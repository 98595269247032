@use '@on3/styles/variables' as var;

@use '@on3/styles/mixins' as mix;

@include mix.homelayout;

.sidebarright {
  position: relative;
}

.metaIcon {
  height: 25px;
  width: 25px;
}
